import store from '..';

export default {
  getCompany({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      store.$api
        .get(import.meta.env.VITE_API_URL + '/companies', {
          params: {
            populate: 'admins,users,connect_account,location'
          },
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        })
        .then((response) => {
          console.log('getCompany', response);
          if (response.status === 200) {
            commit('SET_COMPANY', response.data.data[0] || {});
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) commit('SET_COMPANY', {});
          console.log(error);
          reject(error);
        });
    });
  },
  createCompany({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      store.$api
        .post(
          import.meta.env.VITE_API_URL + '/companies?populate=admins',
          {
            data: payload
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['Authentication/token']
            }
          }
        )
        .then((response) => {
          console.log('createCompany', response);
          if (response.status === 200) {
            commit('SET_COMPANY', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) commit('SET_COMPANY', {});
          console.log(error);
          reject(error);
        });
    });
  },
  updateCompany({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      store.$api
        .put(
          import.meta.env.VITE_API_URL + '/companies/' + payload.id,
          {
            data: { ...payload.attributes, id: undefined }
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['Authentication/token']
            },
            params: {
              populate: 'admins,users,connect_account,location'
            }
          }
        )
        .then((response) => {
          console.log('updateCompany', response);
          if (response.status === 200) {
            commit('SET_COMPANY', response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) commit('SET_COMPANY', {});
          console.log(error);
          reject(error);
        });
    });
  },
  getBillingInfo({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      store.$api
        .post(import.meta.env.VITE_API_URL + '/billing/company', {}, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['Authentication/token']
          }
        }
        )
        .then((response) => {
          console.log('getBillingInfo', response);
          if (response.status === 200) {
            commit('SET_BILLING_INFO', response.data.customer);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};
