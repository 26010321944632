<template>
  <div
    class="pt-10 flex flex-wrap flex-row items-center justify-center scrollbar-hide"
  >
    <div
      class="w-full px-1 max-w-screen-sm m-auto flex flex-col justify-between pb-20"
    >
      <transition name="scale-quick" mode="out-in" appear>
        <div key="loader" class="self-center" v-if="showSkeleton">
          <AppSpinner
            key="spinner"
            v-if="showSkeleton"
            class="h-20 text-primary-500"
          />
        </div>

        <div key="joinRequest" v-else-if="pendingJoinRequest">
          <AppTitle type="page">Setup Company</AppTitle>

          <AppCard class="mb-4">
            <template #title>
              You have a pending join request:
              {{ pendingJoinRequest.name }}
            </template>
            <p>
              Please wait for the company admin to approve your request. You
              will be notified via email.
            </p>
          </AppCard>
        </div>

        <div key="setupCompany" v-else-if="planCode && company?.id">
          <AppTitle type="page">Set up Company</AppTitle>

          <AppCard v-if="!company?.id" class="mb-4 text-sm">
            <p>
              <span class="mr-1 mb-1">ℹ️</span> Has a team member already set up
              your Company? Ask them to invite you by link or QR Code.
            </p>
          </AppCard>

          <p class="my-4">
            Set up your company contact and billing information to get started.
          </p>

          <TextInput label="Company Name" v-model="name" />
          <TextInput label="Company Email" type="email" v-model="email" />
          <TextInput
            label="Company Phone"
            type="tel"
            v-model="contact_number"
          />
          <div class="mb-2 opacity-50 text-sm italic">
            Will be used for communication regarding billing.
          </div>

          <AddCard
            v-if="company?.id"
            @update:active="showAddBilling = $event"
            @saveClicked="saveBillingInfo"
            :active="showAddBilling"
            :pulse="updatingBilling"
            class="my-4"
          >
            <template #title>Invoicing details (optional)</template>
            <template #content>
              <TextInput label="Legal Name" v-model="billing.legal_name" />
              <TextInput
                label="VAT/Company Number"
                v-model="billing.legal_number"
              />
              <TextInput
                label="Address Line 1"
                v-model="billing.address_line1"
              />
              <TextInput
                label="Address Line 2"
                v-model="billing.address_line2"
              />
              <TextInput label="City" v-model="billing.city" />
              <TextInput label="Postal Code" v-model="billing.zipcode" />
            </template>
          </AddCard>

          <div v-if="planCode && plan">
            You are subscribing to the
            <b
              >{{
                planCode.toUpperCase() == 'ULTIMATE'
                  ? 'TEAM'
                  : planCode.toUpperCase()
              }}
              Plan</b
            >
            @ {{ formatPrice(plan.amount_cents / 100) }} / month
          </div>
          <AppButton
            v-if="planCode"
            :pulse="redirecting"
            @click="getPaymentLink"
            class="w-max my-4"
          >
            <template #icon>
              <IconPack
                type="CreditCard"
                class="cursor-pointer h-6 w-6 text-white"
              />
            </template>
            Continue to Card Information
          </AppButton>
          <AppButton
            @click="createCompany"
            v-else-if="!planCode"
            :pulse="redirecting"
            class="w-max my-4"
          >
            <template #icon>
              <IconPack
                type="BuildingOffice2"
                class="cursor-pointer h-6 w-6 text-white"
              />
            </template>
            Create company
          </AppButton>

          <p v-if="planCode && !plan.pay_in_advance" class="opacity-60">
            On the next step, you may be charged R1 - R5 when confirming your
            card details, this will automatically be refunded.
            <b>
              Your first invoice will be generated on the 1st of next month.
            </b>
          </p>
          <p v-else-if="planCode && plan.pay_in_advance" class="opacity-60">
            Your first invoice will be generated immediately,
            <b>charged pro-rata for the remainder of this month.</b>
          </p>
        </div>
        <div class="w-full" v-else-if="company?.id">
          <AppTitle type="page">
            Company: {{ company?.attributes?.name }}
          </AppTitle>

          <AppCard
            clickable
            @click="
              () => {
                $modal.show('EditCompanyModal', company);
              }
            "
            v-if="company?.attributes.logo"
            class="flex justify-center items-center p-6 mb-4"
          >
            <img :src="company?.attributes.logo" class="m-auto w-48" />
            <template #topRight>
              <IconPack
                type="PencilSquare"
                class="cursor-pointer h-5 w-5 text-gray-400"
              />
            </template>
          </AppCard>

          <p class="my-4">
            Your company info can be managed within the app.
            <b>Payment methods are managed on this website.</b>
            <span class="font-bold" v-if="!isAdmin">
              Only admins can manage billing.
            </span>
            Continue in the app to generate documents.
          </p>

          <AppButton
            :pulse="redirecting"
            v-if="isAdmin && isSubscriptionActive"
            @click="getPaymentLink"
            class="w-max my-4"
          >
            <template #icon>
              <IconPack
                type="CreditCard"
                class="cursor-pointer h-6 w-6 text-white"
              />
            </template>
            Update Card Information
          </AppButton>
          <a v-else-if="isAdmin && !isSubscriptionActive" href="/subscription">
            <AppButton class="w-max my-4">
              <template #icon>
                <IconPack
                  type="CreditCard"
                  class="cursor-pointer h-6 w-6 text-white"
                />
              </template>
              Setup Subscription
            </AppButton>
          </a>
        </div>
      </transition>
    </div>
    <EditCompanyModal />
  </div>
</template>
<script>
import { event } from 'vue-gtag';

import AddCard from '@/components/EW/AddCard.vue';
import EditCompanyModal from '../../components/Modals/EditCompanyModal.vue';

export default {
  components: {
    AddCard,
    EditCompanyModal
  },
  data() {
    return {
      billing: {
        legal_name: '',
        legal_number: '',
        address_line1: '',
        address_line2: '',
        city: '',
        zipcode: ''
      },
      updatingBilling: false,
      showAddBilling: false,
      redirecting: false,
      name: '',
      email: '',
      contact_number: '',
      editModal: false
    };
  },
  watch: {
    user(val) {
      if (!this.email && val) {
        this.email = val?.email;
      }

      if (!this.contact_number && val) {
        this.contact_number = val?.contact_number;
      }
    },
    company: function (val) {
      if (!val?.id) return;

      this.name = this.company?.attributes?.name;
      this.email = this.company?.attributes?.email;
      this.contact_number = this.company?.attributes?.contact_number;
    },
    billingInfo: function (val) {
      if (val) {
        this.billing = {
          legal_name: val?.legal_name,
          legal_number: val?.legal_number,
          address_line1: val?.address_line1,
          address_line2: val?.address_line2,
          city: val?.city,
          zipcode: val?.zipcode
        };
      }
    }
  },
  computed: {
    isSubscriptionActive() {
      return this.company?.attributes?.subscription_status === 'active';
    },
    showSkeleton() {
      return !this.company || (this.planCode && !this.plan);
    },
    planCode() {
      const planCodes = ['SOLO', 'BASIC', 'ULTIMATE'];
      return planCodes.includes(this.$route.params.plan_code?.toUpperCase())
        ? this.$route.params.plan_code
        : null;
    },
    plan() {
      if (!this.planCode) return null;
      return this.$store.getters['Subscriptions/planByCode'](this.planCode);
    },
    currentUrl() {
      return window.location.href;
    },
    company() {
      return this.$store.getters['Company/company'];
    },
    billingInfo() {
      return this.$store.getters['Company/billingInfo'];
    },
    pendingJoinRequest() {
      if (
        !this.user ||
        !this.user.pending_join_requests ||
        this.user.pending_join_requests.length === 0 ||
        this.company?.id
      )
        return null;

      return this.user.pending_join_requests[0];
    }
  },
  methods: {
    saveBillingInfo(event) {
      event.preventDefault();

      this.updatingBilling = true;

      this.$store
        .dispatch('Company/updateCompany', {
          id: this.company.id,
          billing: this.billing
        })
        .then(() => {
          this.updatingBilling = false;
          this.$toast.success('Billing information updated successfully');
        })
        .catch((err) => {
          this.updatingBilling = false;
          this.$toast.error(err.message);
        });
    },

    getPaymentLink() {
      this.checkoutInitiated();

      this.redirecting = true;

      this.$store.$api
        .post(
          import.meta.env.VITE_API_URL +
            `/companies/${this.company.id}/subscribe`,
          {
            plan_code: this.planCode,
            email: this.email,
            contact_number: this.contact_number
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters['Authentication/token']}`
            }
          }
        )
        .then((res) => {
          this.redirecting = false;

          window.location.href = res.data.data.authorization_url;
        })
        .catch((err) => {
          this.redirecting = false;
          this.$toast.error(err.message);
        });
    },

    checkoutInitiated() {
      event('initiate-payment-gateway', { method: 'Google' });
    },

    createCompany(event) {
      event.preventDefault();

      if (!this.validateForm(event)) {
        return;
      }

      this.$store
        .dispatch('Company/createCompany', {
          name: this.name,
          email: this.email,
          contact_number: this.contact_number
        })
        .then(() => {
          this.redirecting = false;
          this.$toast.success('Company created successfully');
          this.$router.push('/company');
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    }
  },
  mounted() {
    this.$store.dispatch('Authentication/getUserProfile');

    this.$store.dispatch('Company/getCompany');
    this.$store.dispatch('Company/getBillingInfo');

    if (this.planCode) {
      this.$store.dispatch('Subscriptions/getPlans');

      event('initiate-subscribe', { method: 'Google' });
    }

    if (this.company?.id) {
      this.name = this.company?.attributes?.name;
      this.email = this.company?.attributes?.email;
      this.contact_number = this.company?.attributes?.contact_number;
    } else if (this.user) {
      this.email = this.user?.email;
    }
  }
};
</script>
