<template>
  <transition name="scale-quick" mode="out-in" appear>
    <AppCard v-if="!summary" class="w-full">
      <template #title> Location </template>

      <TextInput
        :pulse="fetchingLocation"
        @blur="blurLocationInput"
        @focus="focusLocationInput"
        ref="locationInput"
        v-model="location"
        class="w-full"
        placeholder="Enter Location"
        @input="fetchLocationAutocompleteResults(location)"
      >
        <template #icon>
          <IconPack type="MapPin" class="h-5 w-5 text-red-400" solid />
        </template>
      </TextInput>
      <div
        ref="autocomplete"
        v-if="locationAutocompleteResults?.length > 0"
        class="absolute -translate-y-1 z-10 w-11/12 bg-white rounded-md border border-gray-200 mt-1 shadow-lg"
      >
        <ul>
          <li
            v-for="result in locationAutocompleteResults"
            :key="result.place_id"
            @click="
              result.place_id == 'current-location'
                ? useCurrentLocation()
                : selectLocation(result)
            "
            class="cursor-pointer px-4 py-4 hover:bg-gray-100"
            :class="{
              'flex gap-2 items-center': result.place_id == 'current-location'
            }"
          >
            <IconPack
              v-if="result.place_id == 'current-location'"
              type="MapPin"
              class="h-5 w-5 text-primary-400"
            />
            {{ result.description }}
          </li>
        </ul>
      </div>
      <AppSwitch
        v-model="fillLocation"
        rightLabel="Manually enter details"
        class="mt-4"
        label="Use Current Location"
      />
      <transition name="scale-quick" mode="out-in" appear>
        <div v-if="fillLocation" class="w-full flex flex-col gap-2 mt-8">
          <TextInput
            ref="addressInput"
            class="w-full"
            v-model="address"
            label="Address"
          />
          <TextInput
            ref="suburbInput"
            class="w-full"
            v-model="suburb"
            label="Suburb"
          />
          <TextInput
            ref="cityInput"
            class="w-full"
            v-model="city"
            label="City"
          />
          <TextInput
            ref="postalCode"
            class="w-1/3"
            v-model="postal_code"
            label="Postal Code"
          />
          <TextInput
            class="w-1/3"
            v-model="stand_number"
            label="Stand / Erf Number"
          />
          <TextInput
            class="w-full"
            v-model="buildingName"
            label="Building Name"
          />
          <AppCard class="w-full">
            <template #title> GPS Coordinates </template>
            <div class="w-full flex flex-col gap-2">
              <TextInput
                ref="latInput"
                class="w-1/2"
                v-model="longitude"
                label="Latitude"
              />
              <TextInput
                ref="longInput"
                class="w-1/2"
                v-model="latitude"
                label="Longitude"
              />
            </div>
            <div
              @click="useCurrentLocation"
              v-if="!longitude || !latitude"
              class="flex gap-2 cursor-pointer hover:bg-gray-100 transition-all duration-300 items-center border rounded-md p-2 w-fit"
            >
              <IconPack type="MapPin" class="h-4 w-4 text-gray-400" />
              <p class="text-gray-400 font-normal">
                Use current GPS coordinates
              </p>
            </div>
          </AppCard>
        </div>
      </transition>
      <div class="w-1/3 m-auto flex justify-center items-center">
        <AppButton @click="saveLocation()" class="mt-5 px-16" type="primary"
          >Save</AppButton
        >
      </div>
    </AppCard>
    <AppCard v-else class="w-full">
      <template #title> Location </template>
      <template #topRight>
        <div class="flex gap-4">
          <tippy>
            <div
              @click="this.$emit('edit')"
              class="hover:bg-gray-200 rounded-full p-1.5 transition-all duration-300"
            >
              <IconPack type="PencilSquare" class="h-5 w-5 text-primary-500" />
            </div>
            <template #content>
              <p class="text-sm">Edit Location</p>
            </template>
          </tippy>
        </div>
      </template>
      <!-- map -->
      <AppCard class="mt-2" style="padding: 0%">
        <div class="flex gap-10">
          <div
            class="w-60 h-44 bg-gray-100 overflow-hidden flex items-center justify-center rounded-l-md"
          >
            <transition name="scale" mode="out-in" appear>
              <img
                v-if="map_image"
                :src="map_image"
                class="scale-[2.3]"
                alt=""
              />
              <IconPack v-else type="Map" class="h-8 w-8 text-gray-300" />
            </transition>
          </div>
          <div
            v-if="!loading && this.stateLocation"
            class="w-full h-full flex flex-col gap-2 p-4"
          >
            <p class="text-primary-500 font-bold">{{ address }}</p>
            <p class="text-gray-700 w-1/2">{{ suburb }}</p>
            <p class="text-gray-700">{{ city }}</p>
            <p class="text-gray-700">{{ postal_code }}</p>
            <p class="text-gray-700">{{ stand_number }}</p>
          </div>
        </div>
      </AppCard>
    </AppCard>
  </transition>
  <ErrorModal ref="errorModal" v-model="showError" />
</template>

<script>
import axios from 'axios';
import AppSwitch from '@/components/EW/AppSwitch.vue';
import { Tippy } from 'vue-tippy';
import ErrorModal from '@/components/Modals/ErrorModal.vue';

export default {
  name: 'AddLocationView',
  components: {
    AppSwitch,
    Tippy,
    ErrorModal
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    summary: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'save', 'edit'],
  data() {
    return {
      location: '',
      locationAutocompleteResults: [],
      fetchingLocation: false,
      fillLocation: false,
      address: '',
      suburb: '',
      city: '',
      postal_code: '',
      stand_number: '',
      buildingName: 'Residential',
      longitude: '',
      latitude: '',
      province: '',
      map_image: '',
      showError: false
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    stateLocation() {
      return this.$store.getters['Location/location'];
    },
    loading() {
      return this.$store.getters['Location/loading'];
    }
  },
  mounted() {
    if (this.modelValue) {
      this.location = `${this.modelValue.address_line}, ${this.modelValue.suburb}, ${this.modelValue.city}`;
      this.address = this.modelValue.address_line;
      this.suburb = this.modelValue.suburb;
      this.city = this.modelValue.city;
      this.postal_code = this.modelValue.postal_code;
      this.stand_number = this.modelValue.stand_number;
      this.buildingName = this.modelValue.buildingName;
      this.longitude = this.modelValue.longitude;
      this.latitude = this.modelValue.latitude;
      this.province = this.modelValue.province;
      this.fillLocation = true;
    }

    if (this.stateLocation) {
      this.$store
        .dispatch('Location/getLocation', this.stateLocation?.attributes?.uid)
        .then(() => {
          this.map_image = this.stateLocation.attributes.map_image;
        });
    } else {
      this.fillLocation = false;
    }
  },
  methods: {
    async fetchLocationAutocompleteResults(input) {
      debounce(async () => {
        try {
          this.fetchingLocation = true;
          if (!input) {
            this.locationAutocompleteResults = [];
            this.fetchingLocation = false;
            return;
          }

          // Check if input matches the coordinate pattern
          const coordinatePattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
          if (coordinatePattern.test(input.trim())) {
            // Handle coordinates
            const [lat, lng] = input.split(',').map((coord) => coord.trim());

            try {
              const response = await axios.get(
                `${
                  import.meta.env.VITE_API_URL
                }/locations/google/place/geocode`,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters['Authentication/token']}`
                  },
                  params: {
                    latitude: lat,
                    longitude: lng
                  }
                }
              );

              const address_components =
                response.data.results[0].address_components;
              const coordinates = {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
              };
              this.location = response.data.results[0].formatted_address;

              // Populate input fields with geocoded data
              this.populateFields(address_components, coordinates);
              this.locationAutocompleteResults = [];
              this.fetchingLocation = false;
              this.fillLocation = true;
            } catch (error) {
              console.error('Error fetching address from coordinates:', error);
              this.fetchingLocation = false;
              this.$toast.error('Error fetching address from coordinates', {
                position: 'bottom',
                onClick: () => {
                  this.$refs.errorModal.error = error;
                  this.showError = true;
                }
              });
            }
          } else {
            // Handle regular address search with Google Autocomplete
            const response = await axios.get(
              `${import.meta.env.VITE_API_URL}/locations/google/autocomplete`,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters['Authentication/token']}`
                },
                params: {
                  query: input
                }
              }
            );

            this.locationAutocompleteResults = response.data.predictions;
            if (this.locationAutocompleteResults?.length <= 0)
              this.focusLocationInput();
            this.fetchingLocation = false;
          }
        } catch (error) {
          console.error('Error fetching location autocomplete results:', error);
          this.$toast.error('Error fetching location autocomplete results', {
            position: 'bottom',
            onClick: () => {
              this.$refs.errorModal.error = error;
              this.showError = true;
            }
          });
        }
      }, 250);
    },

    selectLocation(location) {
      // Handle selection logic here
      this.location = location.description;
      this.locationAutocompleteResults = [];

      // Fetch the coordinates from the place_id
      this.fetchingLocation = true;
      axios
        .get(`${import.meta.env.VITE_API_URL}/locations/google/place/details`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters['Authentication/token']}`
          },
          params: {
            place_id: location.place_id
          }
        })
        .then((response) => {
          const coordinates = response.data.result.geometry.location;

          // Populate input fields
          this.populateFields(
            response.data.result.address_components,
            coordinates
          );

          this.fetchingLocation = false;
          this.fillLocation = true;
        })
        .catch((error) => {
          console.error('Error fetching coordinates from place_id:', error);
          this.fetchingLocation = false;
          this.$toast.error('Error fetching location autocomplete results', {
            position: 'bottom',
            onClick: () => {
              this.$refs.errorModal.error = error;
              this.showError = true;
            }
          });
        });
    },

    populateFields(address_components, coordinates) {
      this.address = `${address_components[0]?.long_name} ${address_components[1]?.long_name}`;
      this.suburb = address_components[2]?.long_name;
      this.city = address_components[3]?.long_name;
      this.province = address_components[5]?.long_name;
      this.postal_code = address_components[7]?.long_name;
      this.longitude = coordinates.lng;
      this.latitude = coordinates.lat;
    },

    async useCurrentLocation() {
      if (navigator.geolocation) {
        try {
          const position = await this.getCurrentPosition();
          if (position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.fetchingLocation = true;

            // Fetch the address from the coordinates
            try {
              const response = await axios.get(
                `${
                  import.meta.env.VITE_API_URL
                }/locations/google/place/geocode`,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters['Authentication/token']}`
                  },
                  params: {
                    latitude: this.latitude,
                    longitude: this.longitude
                  }
                }
              );

              const address_components =
                response.data.results[0].address_components;
              const coordinates = response.data.results[0].geometry.location;
              this.location = response.data.results[0].formatted_address;

              // Populate input fields
              this.populateFields(address_components, coordinates);
              this.fetchingLocation = false;
              this.fillLocation = true;
            } catch (error) {
              console.error('Error fetching address from coordinates:', error);
              this.fetchingLocation = false;
              this.$toast.error('Error fetching address from coordinates', {
                position: 'bottom',
                onClick: () => {
                  this.$refs.errorModal.error = error;
                  this.showError = true;
                }
              });
            }
          }
        } catch (error) {
          console.error('Error getting current location:', error);
        }
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    },

    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    },

    blurLocationInput() {
      setTimeout(() => {
        if (this.$refs.autocomplete)
          this.$refs.autocomplete.style.display = 'none';
      }, 200);
    },

    focusLocationInput() {
      if (this.fillLocation) {
        this.fillLocation = false;
      }
      if (this.locationAutocompleteResults?.length > 0) {
        this.fetchLocationAutocompleteResults(this.location);
        this.$refs.autocomplete.style.display = 'block';
      } else {
        if (this.location?.length > 0) {
          this.fetchLocationAutocompleteResults(this.location);
          return;
        }
        this.locationAutocompleteResults.push({
          description: 'Use Current Location',
          place_id: 'current-location'
        });
      }
    },
    saveLocation() {
      // check if all fields are filled
      if (!this.fillLocation) {
        if (!this.location) {
          this.$refs.locationInput.errorFocus();
          this.$refs.locationInput.errorMessage = 'Please enter a location';
          this.$toast.error('Please enter a location');
          return;
        }
      } else {
        if (!this.address) {
          this.$toast.error('Please enter an address');
          this.$refs.addressInput.errorFocus();
          this.$refs.addressInput.errorMessage = 'Please enter an address';
          return;
        }
        if (!this.suburb) {
          this.$toast.error('Please enter a suburb');
          this.$refs.suburbInput.errorFocus();
          this.$refs.suburbInput.errorMessage = 'Please enter a suburb';
          return;
        }
        if (!this.city) {
          this.$toast.error('Please enter a city');
          this.$refs.cityInput.errorFocus();
          this.$refs.cityInput.errorMessage = 'Please enter a city';
          return;
        }
        // check for valid longitude and latitude
        if (!this.latitude) {
          this.$toast.error('Please enter latitude');
          this.$refs.latInput.errorFocus();
          this.$refs.latInput.errorMessage = 'Please enter latitude';
          return;
        }
        if (!this.longitude) {
          this.$toast.error('Please enter longitude');
          this.$refs.longInput.errorFocus();
          this.$refs.longInput.errorMessage = 'Please enter longitude';
          return;
        }

        // check if correct format
        if (
          this.longitude < -180 ||
          (this.longitude > 180 &&
            typeof this.latitude !== 'number' &&
            typeof this.longitude !== 'number')
        ) {
          this.$toast.error('Please enter valid longitude');
          this.$refs.longInput.errorFocus();
          this.$refs.longInput.errorMessage = 'Please enter valid longitude';

          return;
        }
        if (
          this.latitude < -90 ||
          (this.latitude > 90 &&
            typeof this.latitude !== 'number' &&
            typeof this.longitude !== 'number')
        ) {
          this.$toast.error('Please enter valid latitude');
          this.$refs.latInput.errorFocus();
          this.$refs.latInput.errorMessage = 'Please enter valid latitude';
          return;
        }
      }

      this.model = {
        uid: '',
        address_line: this.address,
        suburb: this.suburb,
        city: this.city,
        stand_number: this.stand_number,
        postal_code: this.postal_code,
        province: this.province,
        latitude: this.latitude,
        longitude: this.longitude,
        buildingName: this.buildingName
      };
      this.$emit('save');
    }
  },
  watch: {
    modelValue: {
      handler(value) {
        if (value) {
          this.location = `${this.modelValue.address_line}, ${this.modelValue.suburb}, ${this.modelValue.city}`;
          this.address = this.modelValue.address_line;
          this.suburb = this.modelValue.suburb;
          this.city = this.modelValue.city;
          this.postal_code = this.modelValue.postal_code;
          this.stand_number = this.modelValue.stand_number;
          this.buildingName = this.modelValue.buildingName;
          this.longitude = this.modelValue.longitude;
          this.latitude = this.modelValue.latitude;
          this.province = this.modelValue.province;
          this.map_image = this.modelValue.map_image;
          this.$store.dispatch('Location/getLocation', this.modelValue.uid);
          this.fillLocation = true;
        }
      },
      deep: true
    },
    location() {
      if (this.$refs.locationInput) this.$refs.locationInput.errorMessage = '';
    },
    address() {
      if (this.$refs.addressInput) this.$refs.addressInput.errorMessage = '';
    },
    suburb() {
      if (this.$refs.suburbInput) this.$refs.suburbInput.errorMessage = '';
    },
    city() {
      if (this.$refs.cityInput) this.$refs.cityInput.errorMessage = '';
    }
  }
};

let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};
</script>
