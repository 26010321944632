<template>
  <AppModal
    ref="EditCompanyModal"
    style="backdrop-filter: blur(3px)"
    name="EditCompanyModal"
    v-model="openModal"
    v-slot:default="{ params }"
  >
    <div v-if="params?.id" class="w-full h-screen flex relative">
      <div
        @click="hideModal"
        class="w-full opacity-0 h-full z-10 absolute top-0 left-0"
      ></div>

      <div class="w-full max-w-screen-lg px-20 h-fit m-auto py-5 relative">
        <div class="w-full z-20 h-full rounded-xl relative">
          <AppCard
            class="w-full max-h-screen overflow-y-scroll h-fit px-10 pt-6 pb-8 rounded-xl"
          >
            <div class="flex flex-row items-center justify-center gap-2 w-full">
              <IconPack type="BuildingOffice" class="w-10" />
              <AppTitle class="text-2xl">Edit Company Info</AppTitle>
            </div>

            <div class="grid grid-cols-2 gap-4 mt-6">
              <AppCard
                v-if="localCompany?.attributes.logo"
                class="flex col-span-2 justify-center items-center p-6 mb-4"
              >
                <img :src="localCompany?.attributes.logo" class="m-auto w-48" />
              </AppCard>
              <!-- company name -->
              <TextInput
                v-model="localCompany.attributes.name"
                label="Company Name"
                placeholder="Enter company name"
              />
              <!-- contact number -->
              <TextInput
                v-model="localCompany.attributes.contact_number"
                label="Contact Number"
                placeholder="Enter contact number"
              />
              <!-- email -->
              <TextInput
                v-model="localCompany.attributes.email"
                label="Email"
                placeholder="Enter email"
              />
              <!-- Department of labour number -->
              <TextInput
                v-model="localCompany.attributes.dol_number"
                label="Department of Labour Number"
                placeholder="Enter Department of Labour Number"
              />
              <div class="col-span-2">
                <AddLocationView
                  ref="locationView"
                  :summary="!editLocation"
                  v-model="localCompany.attributes.location.data.attributes"
                  @save="saveLocation"
                  @edit="editLocation = true"
                />
              </div>
            </div>
            <!-- save -->
            <transition name="scale-quick" mode="out-in" appear>
              <div v-if="!editLocation" class="flex max-w-[200px] m-auto">
                <AppButton
                  @click="saveCompany"
                  :pulse="submitting"
                  type="primary"
                  class="mt-4"
                >
                  Save
                </AppButton>
              </div>
            </transition>
          </AppCard>
          <div
            @click="hideModal"
            class="w-10 h-10 hover:shadow-sm hover:shadow-white hover:-translate-y-0.5 transition-all duration-300 bg-gray-100 rounded-full absolute top-0 -right-14 flex items-center justify-center"
          >
            <IconPack type="XMark" class="w-5 h-5 text-primary-500" />
          </div>
        </div>
      </div>
    </div>
  </AppModal>
  <ErrorModal ref="errorModal" />
</template>

<script>
import AddLocationView from '../Location/AddLocationView.vue';
import ErrorModal from './ErrorModal.vue';
export default {
  components: {
    AddLocationView,
    ErrorModal
  },
  data() {
    return {
      openModal: false,
      editLocation: false,
      submitting: false,
      localCompany: null
    };
  },
  watch: {
    async openModal(val) {
      if (val) {
        this.localCompany = JSON.parse(JSON.stringify(this.company));
        await this.$store.commit(
          'Location/SET_LOCATION',
          this.company.attributes.location
        );
      }
    }
  },
  computed: {},
  methods: {
    hideModal() {
      this.openModal = false;
      this.$modal.hide('EditCompanyModal');
    },
    saveLocation() {
      this.$store
        .dispatch(
          'Location/createLocation',
          this.company.attributes.location.data.attributes
        )
        .then((res) => {
          this.$toast.success('Location saved successfully');
          this.$refs.locationView.map_image =
            res.data.data.attributes.map_image;
          this.editLocation = false;
        })
        .catch((err) => {
          this.$toast.error('Failed to save location', {
            position: 'bottom',
            onClick: () => {
              this.$refs.errorModal.show(err);
            }
          });
        });
    },
    saveCompany() {
      this.submitting = true;
      this.$store
        .dispatch('Company/updateCompany', this.localCompany)
        .then(() => {
          this.$toast.success('Company updated successfully');
          this.hideModal();
        })
        .catch((err) => {
          this.$toast.error('Failed to update company', {
            position: 'bottom',
            onClick: () => {
              this.$refs.errorModal.show(err);
            }
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>
